
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HomeView',
  components: {

  },
  setup() {
    return {}
  }
})
